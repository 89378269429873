import React from 'react';

import { css } from '@emotion/react';

import SiteNav from './SiteNav';
import {
  inner,
  outer,
} from '../../styles/shared';
import { colors } from '../../styles/colors';

interface SiteNavProps {
  isHome?: boolean;
  isPost?: boolean;
  post?: any;
}

export const SiteHeader: React.FC<SiteNavProps> = props => {
  const { isHome, isPost, post } = props;
  return (
    <header className="site-archive-header no-image" css={[SiteArchiveHeader]}>
      <div css={[outer, SiteNavMain]}>
        <div css={inner}>
          <SiteNav isHome={isHome} isPost={isPost} post={post}/>
        </div>
      </div>
    </header>
  );
};

// tag and author post lists
const SiteArchiveHeader = css`
  .site-header-content {
    position: relative;
    align-items: stretch;
    padding: 12vw 0 20px;
    min-height: 200px;
    max-height: 600px;
  }
`;

const SiteNavMain = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background: ${colors.white};
`;
